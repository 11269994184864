@font-face {
    font-family: "Druk Wide Cyr";
    src: url(../assets/fonts/druke_medium/DrukWideCyr-Medium.eot);
    src: url(../assets/fonts/druke_medium/DrukWideCyr-Medium.eot?#iefix) format("embedded-opentype"),
        url(../assets/fonts/druke_medium/DrukWideCyr-Medium.woff2) format("woff2"),
        url(../assets/fonts/druke_medium/DrukWideCyr-Medium.woff) format("woff"),
        url(../assets/fonts/druke_medium/DrukWideCyr-Medium.ttf) format("truetype"),
        url(../assets/fonts/druke_medium/DrukWideCyr-Medium.svg#DrukWideCyr-Medium) format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Druk Wide Cyr";
    src: url(../assets/fonts/druke_medium/DrukWideCyr-Bold.eot);
    src: url(../assets/fonts/druke_medium/DrukWideCyr-Bold.eot?#iefix) format("embedded-opentype"),
        url(../assets/fonts/druke_medium/DrukWideCyr-Bold.woff2) format("woff2"),
        url(../assets/fonts/druke_medium/DrukWideCyr-Bold.woff) format("woff"),
        url(../assets/fonts/druke_medium/DrukWideCyr-Bold.ttf) format("truetype"),
        url(../assets/fonts/druke_medium/DrukWideCyr-Bold.svg#DrukWideCyr-Bold) format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
