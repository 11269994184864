.scoresheet-time-picker {
  width: 200px;

  .ant-picker-panel-container {
    background-color: #1b1c19;
  }

  .ant-picker-panel,
  .ant-picker-time-panel,
  .ant-picker-content {
    width: 100%;
  }

  .ant-picker-time-panel-cell {
    font-family: 'Boxed Medium';
    font-weight: 500;
    font-size: 18px;
  }

  .ant-picker-time-panel-cell-selected {
    .ant-picker-time-panel-cell-inner {
      background-color: #cafc01 !important; // Due to library nesty
      color: #363731 !important; // Due to library nesty
    }
  }

  .ant-picker-time-panel-cell-inner {
    height: 32px !important; // Due to library nesty
    line-height: 32px !important; // Due to library nesty
    padding-left: 0 !important; // Due to library nesty
    text-align: center !important; // Due to library nesty
  }

  .ant-picker-footer {
    font-family: 'Boxed Regular';
    padding: 6px 16px;
  }

  .ant-picker-time-panel-column {
    padding-bottom: 12px;

    &:after {
      display: none;
    }
  }

  .ant-btn-primary {
    height: 30px;
    padding: 0px 18px;
    border-radius: 6px;
    color: #363731;
    border-color: #cafc01;
    background-color: #cafc01;
    text-shadow: none;
    box-shadow: none;

    &[disabled] {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .ant-picker-ranges {
    display: flex;
    justify-content: center;
  }

  .ant-picker-now {
    display: none;
  }

  .ant-picker-ok {
    margin-left: 0;
  }

  .ant-picker-time-panel-column {
    &:first-of-type {
      .ant-picker-time-panel-cell {
        &:nth-child(21) ~ li {
          display: none;
        }
      }
    }
  }
}
