.group-wrapper {
    padding: 20px;
    @media (max-width: 768px) {
        padding: 20px 0px;
    }
    h4 {
        font-size: 12px;
        margin: 10px 0;
    }
}
