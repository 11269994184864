@import '../../styles/colors.scss';

.header-menu {
  width: 100%;
  height: 72px;
  display: flex;
  border-bottom: solid 1px $grey-light;
  position: fixed;
  background-color: $grey-dark;
  z-index: 100;
  @media (max-width: 768px) {
    display: none;
  }

  .logo {
    height: 72px;
    width: 72px;
    background-color: $orange;
    background-image: url('/assets/imgs/Vault_logo_grey.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40%;
  }

  .menu-top {
    display: flex;
    width: calc(100% - 144px);

    ul {
      width: 100%;
      display: flex;
      justify-content: flex-start;

      li {
        font-family: 'Druk Wide Cyr';
        font-size: 11px;
        text-transform: uppercase;
        text-align: center;
        padding: 30px 0;
        list-style: none;
        width: 300px;

        &.offset-right {
          width: fit-content;
          margin-left: auto;
        }
      }
    }
  }

  .manage-icons {
    display: flex;
    margin-left: auto;

    .box-action-top {
      width: 72px;
      height: 72px;
      border-left: solid 1px $grey-light;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 30%;

      &.icon-notification {
        background-image: url('/assets/imgs/icons/ico_mail_line.svg');
      }

      &.icon-manage {
        background-image: url('/assets/imgs/icons/ico_manage.svg');
      }

      &.icon-search {
        background-image: url('/assets/imgs/icons/ico_search.svg');
      }
    }

    .active {
      .icon-manage {
        background-image: url('/assets/imgs/icons/ico_manage_orange.svg');
      }
    }
  }
}
