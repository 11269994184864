@import "../../../styles/colors.scss";

.group-box {
    padding: 24px;
    background-color: $grey-light;
    border-radius: 24px;
    overflow-x: auto;
    .page-separator {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        z-index: 0;
        &::before {
            content: "";
            height: 1px;
            width: 100%;
            top: 50%;
            position: absolute;
            z-index: -1;
            background-color: $silver-light;
        }
        .page-separator__text {
            background-color: $grey-light;
            padding: 10px 0;
            display: inline-flex;
            max-width: 90%;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 2px;
        }
    }
}
