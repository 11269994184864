@import "../../../styles/colors.scss";

.container {
    padding: 20px;
    width: 100%;
    .container-wrapper {
        //max-width: 1000px;
        margin: 0 auto;
        .breadcumbs {
            font-weight: 700;
            color: $silver-light;
        }
        .content {
            margin: 20px 0;
        }
    }
}
