@font-face {
        font-family: 'Boxed Book';
        src: local('Boxed Book'), local('BoxedBook'),
        url('../../assets/fonts/boxed/BoxedBook.woff2') format('woff2'),
        url('../../assets/fonts/boxed/BoxedBook.woff') format('woff');
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Boxed Extralight';
        src: local('Boxed Extralight'), local('BoxedExtralight'),
        url('../../assets/fonts/boxed/BoxedExtralight.woff2') format('woff2'),
        url('../../assets/fonts/boxed/BoxedExtralight.woff') format('woff');
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Boxed Heavy';
        src: local('Boxed Heavy'), local('BoxedHeavy'),
        url('../../assets/fonts/boxed/BoxedHeavy.woff2') format('woff2'),
        url('../../assets/fonts/boxed/BoxedHeavy.woff2') format('woff');
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Boxed Light';
        src: local('Boxed Light'), local('BoxedLight'),
        url('../../assets/fonts/boxed/BoxedLight.woff2') format('woff2'),
        url('../../assets/fonts/boxed/BoxedLight.woff') format('woff');
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Boxed Medium';
        src: local('Boxed Medium'), local('BoxedMedium'),
        url('../../assets/fonts/boxed/BoxedMedium.woff2') format('woff2'),
        url('../../assets/fonts/boxed/BoxedMedium.woff') format('woff');
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Boxed Regular Bold';
        src: local('Boxed Regular Bold'), local('BoxedRegularBold'),
        url('../../assets/fonts/boxed/BoxedRegular-Bold.woff2') format('woff2'),
        url('../../assets/fonts/boxed/BoxedRegular-Bold.woff') format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Boxed Regular';
        src: local('Boxed Regular'), local('BoxedRegular'),
        url('../../assets/fonts/boxed/BoxedRegular.woff2') format('woff2'),
        url('../../assets/fonts/boxed/BoxedRegular.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Boxed Semibold';
        src: local('Boxed Semibold'), local('BoxedSemibold'),
        url('../../assets/fonts/boxed/BoxedSemibold.woff2') format('woff2'),
        url('../../assets/fonts/boxed/BoxedSemibold.woff') format('woff');
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Boxed Thin';
        src: local('Boxed Thin'), local('BoxedThin'),
        url('../../assets/fonts/boxed/BoxedThin.woff2') format('woff2'),
        url('../../assets/fonts/boxed/BoxedThin.woff') format('woff');
        font-style: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'UF Display';
        src: local('UF Display'), local('UFDisplay'),
        url('../../assets/fonts/uf_display/UFDisplay-Regular.woff2') format('woff2'),
        url('../../assets/fonts/uf_display/UFDisplay-Regular.woff') format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }