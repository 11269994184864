// Solid Colors
$orange: #fd502f;
$orange-light: #fd922f;
$grey-dark: #24272c;
$grey-dark-opacity: #24272ccc;
$grey-light: #2e3137;
$grey-light-opacity: #2e3137cc;
$greyText: #878b93;
$silver: #53575f;
$silver-light: #80838a;
$silver-light-shadow: #40444b;
// Gradient
$gradient-orange: linear-gradient(180deg, #fd922f 0%, #fd502f 110%);
