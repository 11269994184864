.transition_fade-enter {
    opacity: 0.01;
}

.transition_fade-enter.transition_fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.transition_fade-appear {
    opacity: 0.01;
}

.transition_fade-appear.transition_fade-appear-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.transition_fade-app {
    opacity: 0.01;
}

.transition_fade-enter.transition_fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.transition_fade-exit {
    opacity: 1;
}

.transition_fade-exit.transition_fade-exit-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
}
