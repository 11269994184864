.ant-select {
  display: block;

  .ant-select-selector {
    padding: 10px 20px !important;
    min-height: 45px !important;
    border-radius: 8px !important;
    border: 1px solid $grey-light !important;
    background-color: $grey-dark !important;
    transition: none !important;

    .ant-select-selection-item {
      line-height: 22px;
    }
  }

  &.user-select {
    .ant-select-selector {
      .ant-select-selection-search {
        padding: 10px 50px;
      }
    }
  }

  &.fat {
    .ant-select-selector,
    .ant-select-item-option-content,
    .ant-select-item-option-content {
      height: 65px;

      .option-image {
        display: flex;
        align-items: center;

        .img {
          margin-right: 10px;
          border-radius: 30px;
          overflow: hidden;
        }
      }
    }

    &.select-multiple {
      .ant-select-selector,
      .ant-select-item-option-content,
      .ant-select-item-option-content {
        height: auto;

        .ant-select-selection-search {
          padding: 0;
          z-index: 110;

          &:first-child {
            .ant-select-selection-search-input {
              margin-left: 30px !important;
            }
          }
        }

        .ant-select-selection-search-input {
          min-width: 100px;
        }
      }

      .ant-select-selection-item {
        height: 58px;
        padding: 8px;
        border-radius: 5px;
        box-sizing: border-box;
      }
    }
  }

  &.ant-select-focused {
    .ant-select-selector {
      border: 1px solid $orange !important;
    }
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }

  &.small {
    .ant-select-selection-placeholder {
      padding: 15px;
    }
  }
}

.ant-select-item-option-content {
  .option-image {
    display: flex;
    align-items: center;
    .img {
      margin-right: 10px;
      border-radius: 30px;
      overflow: hidden;
    }
  }
}

.ant-input-number,
.ant-form-item-control-input-content textarea {
  padding: 10px 20px !important;
  border-radius: 8px !important;
  border: 1px solid $grey-light !important;
  background-color: $grey-dark !important;
  transition: none !important;
}

.ant-picker {
  border: none !important;
  padding: 0 !important;
}

.icon .ant-select-selector,
.icon .ant-picker-input input {
  padding-left: 50px !important;
}

.ant-table {
  background: none;
  font-weight: 600;
  font-size: 11px;
  color: #fff;
  table {
    border-collapse: collapse;
  }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  border-bottom: none;
  font-size: 11px;
}

.ant-table-thead > tr > th {
  background: none;
  background-color: $grey-dark;
}

.ant-upload.ant-upload-select {
  display: block;
}

.ant-table-row {
  border-bottom: solid 1px $grey-dark;

  &.finished-game-row {
    background-color: $orange;
  }

  &.scheduled-game-row {
    background-color: $grey-dark;
  }

  .label-status {
    padding: 5px 15px;
    border-radius: 5px;
    text-align: center;
    border: solid 1px #ccc;
  }
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none;
}

.ant-pagination-item-active {
  border-color: $orange;

  a {
    color: $orange;
    font-size: 12px;
  }
}

.ant-input-number {
  border: none;
  width: 100%;
  box-shadow: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.ant-select-focused {
  padding: 0;
}

.ant-form-item-control-input-content {
  display: flex;
}

.form-group input[type='text'],
.form-group input[type='number'],
select {
  outline: none;
  &.icon {
    padding-left: 50px;
  }
}

.ant-picker {
  width: 100%;
}

.ant-picker.icon.ant-picker-disabled {
  background: none;
}

.ant-picker-input {
  .ant-picker-suffix {
    position: absolute;
    right: 10px;
    z-index: 10;
  }

  input {
    outline: none;
    padding-left: 20px !important;
    height: 45px !important;
    border-radius: 8px !important;
    border: 1px solid $grey-light !important;
    background-color: $grey-dark !important;
    transition: none !important;
    margin: 5px 0;
  }
}

.ant-picker-clear {
  display: none;
}

.ant-input-number-input-wrap {
  input[type='number'] {
    padding: 20px;
    padding-left: 50px;
    outline: none;
  }
}

.ant-form-item-explain.ant-form-item-explain-error {
  color: $orange;
  font-weight: 800;
  font-size: 10px;
  padding: 5px;
}

.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: $orange !important;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  padding: 20px 10px;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input).small
  .ant-select-selector {
  input {
    padding: 0;
  }
}

.icon
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  padding: 20px 35px;
}

.ant-message-notice-content {
  font-size: 15px;
  font-weight: 900;
  padding: 18px 25px;
  border-radius: 10px;
  margin-top: 8vh;
}

.disable-entire-scroll {
  max-height: 100vh;
  overflow: hidden;
}
