body,
html {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  background-color: $grey-dark;
  color: #fff;
  height: 100%;
  @media print {
    @page {
      size: landscape;
      margin: 10px;
    }
    background: none !important;
  }
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    color: inherit;
  }
  &.link:hover {
    color: $orange;
  }
  &.active {
    button {
      background-color: $orange !important;
      color: #fff !important;
    }
  }
  &.active:hover {
    button {
      color: #fff !important;
    }
  }
}
[role='button']:focus {
  outline: none;
}

h1,
h2,
h3,
h4 {
  font-family: 'Druk Wide Cyr';
  color: inherit;
}

h1 {
  @media (max-width: 768px) {
    font-size: 1.5em;
  }
}

.text-grey {
  color: $silver;
}
.text-grey-light {
  color: $silver-light;
}
.text-orange {
  color: $orange;
}
.bold {
  font-weight: 600;
}
.italic {
  font-style: italic;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

.text-gradient {
  background: $gradient-orange;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.font-sm {
  font-size: 10px;
}

.font-d {
  font-family: 'Druk Wide Cyr';
}
.form-group {
  margin: 5px 0;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  .field {
    position: relative;
  }
  label,
  .label {
    //margin-left: 10px;
    font-size: 14px;
    color: $greyText;
    font-weight: 500;
  }
  input[type='text'],
  input[type='number'],
  textarea,
  select {
    font-family: 'Montserrat', sans-serif;
    background: $grey-dark;
    color: #fff;
    width: 100%;
    min-height: 45px;
    padding: 10px 20px;
    border-style: none;
    border-radius: 8px;
    border: 1px solid $grey-light;
    box-sizing: border-box;
    font-size: 14px;
    &:focus {
      outline: none;
      border: 1px solid $orange;
    }
    &:disabled {
      color: $silver;
      cursor: not-allowed;
    }
  }
  /* Overide style for Safari */
  select {
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNSAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjIzMTIgMC44NjU2NjJMNy41IDYuNTk2ODlMMS43Njg3NyAwLjg2NTY2MkwwIDIuNjM0MzdMNy41IDEwLjEzNDRMMTUgMi42MzQzN0wxMy4yMzEyIDAuODY1NjYyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==)
      no-repeat 95% 50%;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: $grey-dark;
    border: 1px solid $grey-light;
  }
  /* Chrome, Safari, Edge, Opera */
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input.icon {
    padding-left: 55px;
  }
  button.btn,
  input[type='submit'] {
    border-style: none;
    padding: 15px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    background-color: $orange;
    color: #fff;
    border-radius: 5px;
    width: 100%;
    font-size: 0.9em;
    font-weight: 700;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      background-color: $orange-light;
    }
    &.block {
      display: inline-block;
      width: auto;
      padding: 15px 40px;
    }
  }
}

button.btn {
  border-style: none;
  padding: 15px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  background-color: $orange;
  color: #fff;
  border-radius: 5px;
  width: 100%;
  font-size: 0.9em;
  font-weight: 700;
  cursor: pointer;
  text-transform: uppercase;
  &.sm {
    padding: 5px 10px;
  }
  &:hover {
    background-color: $orange-light;
  }
  &.white {
    background-color: #fff !important;
    color: $silver !important;
    &:hover {
      background: $silver-light !important;
      color: $silver !important;
    }
  }
  &.transparent {
    color: #fff;
    background-color: transparent !important;
    border: 1px solid;
    &:hover {
      color: $silver !important;
    }
  }
}

.form-group-wrapper {
  position: relative;

  .icon {
    top: 2px;
  }
}

.bt-white {
  background-color: #fff !important;
  color: $silver !important;
  &:hover {
    background: $silver-light !important;
    color: $silver !important;
  }
}

.form-group-row {
  border: solid 1px $silver;
  border-radius: 15px;
  margin: 10px;
  padding: 10px 0;
}

.d-hide {
  display: none;
}

.box-grey {
  padding: 20px;
  border-radius: 20px;
  background-color: $grey-dark;
}
.flex {
  display: flex;
}

span.icon {
  position: absolute;
  top: 5px;
  left: 0;
  z-index: 10;
  opacity: 0.7;

  &.clear {
    position: relative;
    z-index: 10;
    opacity: 1;
    margin-right: 5px;
    display: inline-flex;
  }
  &::before {
    width: 25px;
    height: 25px;
    left: 15px;
    top: 10px;
    position: absolute;
  }
  &.clear::before {
    width: 25px;
    height: 25px;
    left: 0px;
    //top: 3px;
    position: relative;
  }
  &.clear.lg::before {
    width: 40px;
    height: 40px;
    top: 0;
  }
  &.icon-user {
    &::before {
      content: url('../assets/imgs/icons/ico_user.svg');
    }
  }
  &.icon-search {
    &::before {
      content: url('../assets/imgs/icons/ico_search.svg');
    }
  }
  &.icon-tatic {
    &::before {
      content: url('../assets/imgs/icons/icon_tatic.svg');
    }
  }
  &.icon-copy {
    &::before {
      content: url('../assets/imgs/icons/ico_copy.svg');
    }
  }
  &.icon-user-black {
    &::before {
      content: url('../assets/imgs/icons/ico_user_black.svg');
    }
  }
  &.icon-password {
    &::before {
      content: url('../assets/imgs/icons/ico_locker.svg');
    }
  }
  &.icon-house {
    &::before {
      content: url('../assets/imgs/icons/ico_house.svg');
    }
  }
  &.icon-location {
    &::before {
      content: url('../assets/imgs/icons/ico_location.svg');
    }
  }
  &.icon-flag {
    &::before {
      content: url('../assets/imgs/icons/ico_flag.svg');
    }
  }
  &.icon-phone {
    &::before {
      content: url('../assets/imgs/icons/ico_phone.svg');
    }
  }
  &.icon-state {
    &::before {
      content: url('../assets/imgs/icons/ico_state.svg');
    }
  }
  &.icon-city {
    &::before {
      content: url('../assets/imgs/icons/ico_city.svg');
    }
  }
  &.icon-zip {
    &::before {
      content: url('../assets/imgs/icons/ico_zip.svg');
    }
  }
  &.icon-email {
    &::before {
      content: url('../assets/imgs/icons/ico_mail.svg');
    }
  }
  &.icon-check-green {
    &::before {
      content: url('../assets/imgs/icons/ico_check_green.svg');
    }
  }
  &.icon-ruler {
    &::before {
      content: url('../assets/imgs/icons/ico_rule.svg');
    }
  }
  &.icon-club {
    &::before {
      content: url('../assets/imgs/icons/ico_club.svg');
    }
  }
  &.icon-shirt {
    &::before {
      content: url('../assets/imgs/icons/ico_shirt.svg');
    }
  }
  &.icon-shoe {
    &::before {
      content: url('../assets/imgs/icons/ico_shoe.svg');
    }
  }
  &.icon-cast {
    &::before {
      content: url('../assets/imgs/icons/ico_cast.svg');
    }
  }
}

.i-icon-check {
  &::before {
    content: url('../assets/imgs/icons/ico_check_black.svg');
  }
}
.i-icon-play {
  &::before {
    top: 9px !important;
    left: 6px !important;
    content: url('../assets/imgs/icons/ico_play_black.svg');
  }
}
.i-icon-trash {
  &::before {
    content: url('../assets/imgs/icons/ico_trash_black.svg');
  }
}
.i-icon-trash-white {
  &::before {
    content: url('../assets/imgs/icons/ico_trash_white.svg');
  }
}
.i-icon-check-green {
  &::before {
    content: url('../assets/imgs/icons/ico_check_green.svg');
  }
}
.i-icon-edit {
  &::before {
    content: url('../assets/imgs/icons/ico_edit_black.svg');
  }
}
.i-icon-edit-status {
  &::before {
    content: url('../assets/imgs/icons/exchange-alt-solid.svg');
  }
}
.i-icon-reverse {
  &::before {
    content: url('../assets/imgs/icons/reverse-icon.svg');
  }
}
.i-icon-lock {
  &::before {
    content: url('../assets/imgs/icons/ico_lock.svg');
  }
}
.i-icon-list {
  &::before {
    content: url('../assets/imgs/icons/ico_list.svg');
  }
}

.i-icon-add {
  &::before {
    content: url('../assets/imgs/icons/ico_plus_black.svg');
  }
}

.i-icon-close {
  &::before {
    content: url('../assets/imgs/icons/ico_close_black.svg');
  }
}

.i-icon-close-white {
  &::before {
    content: url('../assets/imgs/icons/ico_close.svg');
  }
}

.i-icon-print {
  &::before {
    content: url('../assets/imgs/icons/ico_printing.svg');
  }
}

.i-icon-chevron-right {
  &::before {
    content: url('../assets/imgs/icons/right-arrow.svg');
  }
}

.i-icon-chevron-down {
  &::before {
    content: url('../assets/imgs/icons/icon_chevron_down.svg');
  }
}

.i-icon-chevron-up {
  &::before {
    content: url('../assets/imgs/icons/icon_chevron_up.svg');
  }
}

.i-icon-hourglass {
  &::before {
    content: url('../assets/imgs/icons/ico_hourglass.svg');
  }
}

.i-icon-payment {
  &::before {
    content: url('../assets/imgs/icons/ico_payment.svg');
  }
}
.i-icon-cast {
  &::before {
    background-image: url('../assets/imgs/icons/ico_cast.svg');
    background-size: 20 20px;
    display: inline-block;
    width: 20px;
    height: 20px;
    content: '';
    margin-right: 10px;
  }
}

.i-trophy {
  &::before {
    background: url('../assets/imgs/icons/icon_trophy.svg');
    background-size: cover;
    content: '';
  }
}

.i-ball {
  &::before {
    background: url('../assets/imgs/icons/soccer_white.svg');
    background-size: cover;
    content: '';
  }
}

.i-graph {
  &::before {
    background: url('../assets/imgs/icons/ico_graph.svg');
    background-size: cover;
    content: '';
  }
}

.icon-block {
  &.sm::before {
    width: 15px;
    height: 15px;
  }
  &::before {
    width: 25px;
    height: 25px;
    left: 0px;
    top: 0px;
    position: absolute;
  }
}

span.bt-span-icon {
  position: absolute;
  z-index: 10;
  opacity: 0.7;
  top: 0;
  &.left {
    left: 0px;
  }
  &.right {
    right: 45px;
  }
  &::before {
    width: 16px;
    height: 16px;
    left: 15px;
    top: 10px;
    position: absolute;
  }
  &.icon-goal {
    &::before {
      content: url('../assets/imgs/icons/047-soccer.svg');
    }
  }
  &.icon-foul {
    &::before {
      content: url('../assets/imgs/icons/045-whistle.svg');
    }
  }
  &.icon-yellow-card {
    &::before {
      content: url('../assets/imgs/icons/010-referee-1.svg');
    }
  }
  &.icon-red-card {
    &::before {
      content: url('../assets/imgs/icons/043-cards.svg');
    }
  }
  &.icon-own-goal {
    &::before {
      content: url('../assets/imgs/icons/047-soccer.svg');
    }
  }
  &.icon-pk-goal {
    &::before {
      content: url('../assets/imgs/icons/047-soccer.svg');
    }
  }
  &.icon-pk-miss {
    &::before {
      content: url('../assets/imgs/icons/047-soccer.svg');
    }
  }
  &.icon-edit-white {
    &::before {
      content: url('../assets/imgs/icons/ico_edit_report.svg');
    }
  }
}

.bt-action {
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 20px;
  margin: 0 5px;
  cursor: pointer;
  position: relative;
  &::before {
    position: absolute;
    width: 16px;
    top: 7px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  &:hover {
    background: $silver-light;
  }
}

.card-box {
  background-color: $grey-light;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  &.lg {
    border-radius: 20px;
    .content {
      padding: 20px;
    }
  }
  .feature-image {
    width: 100%;
    max-height: 25vw;
    position: relative;
    .video-icon {
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 100px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      top: calc(50% - 20px);
      background-color: $orange;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.8;
      z-index: 10;
    }
    img {
      transition: all 0.3s;
      transform: scale(1);
      width: 100%;
    }
  }
  .content,
  .content-card {
    padding: 10px;

    .feature-image {
    }

    p {
      font-size: 11px;
      color: $silver-light;
    }
  }
  .content-card {
    padding-top: 20px;
    position: relative;
    background-image: linear-gradient(
      180deg,
      transparent 15%,
      rgba(0, 0, 0, 0.95) 53%
    );
  }
  &:hover {
    .feature-image {
      img {
        transform: scale(1.1);
      }
    }
  }
}

.column-wizard {
  padding: 0 100px;
  max-width: 840px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 0 25px;
  }
}

.column-form {
  padding: 20px;
  @media (max-width: 768px) {
    padding: 0;
  }
}
.menu-empty {
  padding-left: 0 !important;
}

.card-popup {
  border-radius: 10px;
  background-color: $grey-light;
  padding: 30px;
  box-shadow: 0 0 11px 0px #00000082;
  z-index: 10;
}

.hidden-column {
  position: absolute;
  transition: all 0.5s;
  right: -300px;
  &.open {
    right: 0px !important;
  }
}

.tab-card-wrapper {
  display: flex;
  background: $grey-dark;
  padding: 10px 15px;
  border-radius: 10px;
  .item {
    margin: 0 10px;
    padding: 5px 15px;
    font-weight: 400;
    border-radius: 5px;
    font-size: 10px;
    cursor: pointer;
    &:hover {
      color: #ccc;
    }
    &:first-child {
      margin-left: 0;
    }
    &.active {
      background-color: $silver;
      &:hover {
        color: inherit;
        cursor: inherit;
      }
    }
    &.active-orange {
      background-color: $orange;
      &:hover {
        color: inherit;
        cursor: inherit;
      }
    }
  }
}

.styled-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  &::-webkit-scrollbar-track {
    background: $grey-light;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $orange;
    border-radius: 5px; /* roundness of the scroll thumb */
    border: 4px solid $grey-light; /* creates padding around scroll thumb */
  }
}

// Custom Light box CSS
#modal-root {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: all 0.3s;
  visibility: hidden;
  opacity: 0;

  &.open {
    visibility: visible;
    opacity: 1;
  }
  .outside-click {
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
    z-index: 1;
  }
  .close-button {
    content: url('../assets/imgs/icons/ico_close.svg');
    width: 40px;
    height: 40px;
    position: absolute;
    z-index: 100;
    background: $orange;
    right: 50px;
    top: 50px;
    border-radius: 30px;
    padding: 12px;
    cursor: pointer;
    &:hover {
      padding: 10px;
    }
    @media (max-width: 768px) {
      right: 10px;
      top: 10px;
    }
  }
}
.modal-wrapper {
  background-color: $grey-dark-opacity;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
  @media (max-width: 768px) {
    padding: 0;
    //display: block;
    overflow: scroll;
  }
  .content {
    position: relative;
    z-index: 10;
    max-height: 110vh;
  }
}

.ant-tooltip-inner {
  background-color: $orange;
}
.ant-tooltip-arrow-content {
  background-color: $orange;
}
//Custom checkbox
.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 16px;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control_indicator {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  background: $grey-dark;
  border: 1px solid $silver;
  border-radius: 3px;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: $grey-light;
}

.control input:checked ~ .control_indicator {
  background: $grey-light;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: $grey-light;
}
.control input:disabled ~ .control_indicator {
  background: $silver;
  opacity: 0.6;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-checkbox .control_indicator:after {
  left: 7px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid $silver;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
  border-color: $silver;
}
.control-checkbox .control_indicator::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 4.5rem;
  height: 4.5rem;
  margin-left: -1.3rem;
  margin-top: -1.3rem;
  background: $orange;
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}
@keyframes s-ripple {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
@keyframes s-ripple-dup {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
.control-checkbox input + .control_indicator::before {
  animation: s-ripple 250ms ease-out;
}
.control-checkbox input:checked + .control_indicator::before {
  animation-name: s-ripple-dup;
}

//Animation classes
:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}
.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.avatar-table {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
  img {
    width: 100%;
  }
}
